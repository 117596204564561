import { axios } from "@/utils/api/index";

/**
 * Get all users.
 * @param page Current page, starts at 0.
 * @return {Promise<AxiosResponse<any>>}
 */
export const getAll = (page, only_not_validated) => {
  return axios().get("/account/all", {
    params: { page: page, "only-not-validated": only_not_validated }
  });
};

/**
 * Get one user by ID.
 * @param id User ID.
 * @return {Promise<AxiosResponse<any>>}
 */
export const getOne = id => {
  return axios().get(`/account/get/${id}`);
};

/**
 * Create new user.
 * @param first_name First name.
 * @param last_name Last name.
 * @param username Username.
 * @param email Email.
 * @param birth_date Birth date format "DD/MM/YYYY".
 * @param primary_category_identifier Attach category identifier as primary for user.
 * @param how_discovered (Optional) Note about user.
 * @return {Promise<AxiosResponse<any>>}
 */
export const postNew = (
  first_name,
  last_name,
  username,
  email,
  birth_date,
  primary_category_identifier,
  how_discovered
) => {
  return axios().post("/account/new", {
    first_name,
    last_name,
    username,
    email,
    birth_date,
    primary_category_identifier,
    how_discovered
  });
};

/**
 * Registration validation.
 * @param id User ID (UUID).
 * @param accepted Registration accepted or not.
 * @param reason If refuse, provide an reason.
 * @return {Promise<AxiosResponse<any>>}
 */
export const registrationValidation = (id, accepted, reason) => {
  return axios().patch("/account/registration-validation", {
    id,
    accepted,
    reason
  });
};

/**
 * Suspend user.
 * @param id User ID (UUID).
 * @return {Promise<AxiosResponse<any>>}
 */
export const suspend = id => {
  return axios().patch("/account/suspend/" + id);
};

/**
 * Cancel user suspension.
 * @param id User ID (UUID).
 * @return {Promise<AxiosResponse<any>>}
 */
export const cancelSuspension = id => {
  return axios().patch("/account/cancel-suspend/" + id);
};

/**
 * Get profile picture entire URL.
 * @param userProfilePicture User profile picture taken from API response.
 */
export const getProfilePicture = userProfilePicture => {
  return userProfilePicture
    ? "https://art-prise.s3.eu-west-3.amazonaws.com/" + userProfilePicture
    : require("@/assets/default-avatar.png");
};

export default {
  getAll,
  getOne,
  postNew,
  registrationValidation,
  suspend,
  cancelSuspension,
  getProfilePicture
};
