<template>
  <div v-if="prise" class="presentation-block">
    <Modal :data="speakerDeleteModal" v-on:action="speakerDelete" />
    <div class="flex flex-row justify-between items-center" id="user-preview">
      <div>
        <h2 class="text-2xl font-semibold text-gray-700 dark:text-gray-200">
          {{ prise.title }}
        </h2>
      </div>
      <button
        v-if="editMode"
        class="px-3 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
        @click="saveUpdate"
      >
        Sauvegarder
      </button>
      <button
        v-else
        class="px-3 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
        @click="switchEditMode"
      >
        Modifier
      </button>
    </div>
    <div v-if="prise" class="presentation-block" id="top-presentation-block">
      <div
        class="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800"
      >
        <h3
          class="mt-4 mb-2 text-xl font-semibold underline text-gray-700 dark:text-gray-200"
        >
          Infos
        </h3>
        <ul>
          <li>
            <p>Titre:</p>
            <input
              v-if="editMode"
              class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
              v-model="prise.title"
            />
            <span v-else>{{ prise.title }}</span>
          </li>
          <li>
            <p>Intro:</p>
            <textarea
              v-if="editMode"
              class="extended-textarea block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
              v-model="prise.intro"
            />
            <span v-else>{{ prise.intro }}</span>
          </li>
          <li>
            <p>Programme:</p>
            <textarea
              v-if="editMode"
              class="extended-textarea block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
              v-model="prise.program"
            />
            <span v-else>{{ prise.program }}</span>
          </li>
          <li>
            <p>Description:</p>
            <textarea
              v-if="editMode"
              class="extended-textarea block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
              v-model="prise.description"
            />
            <span v-else>{{ prise.description }}</span>
          </li>
          <li>
            <p>URL vidéo YouTube:</p>
            <input
              v-if="editMode"
              class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
              v-model="prise.video_url"
            />
            <a
              v-else
              :href="prise.video_url"
              target="_blank"
              rel="noreferrer nofollow"
              >{{ prise.video_url }}</a
            >
          </li>
          <li>
            <p>Date de début:</p>
            <span v-if="!editMode">{{ dateFormat(prise.start_at) }}</span>
            <datetime
              type="datetime"
              v-else-if="editMode"
              v-model="prise.start_at"
            ></datetime>
          </li>
          <li>
            <p>Date de fin:</p>
            <span v-if="!editMode">{{ dateFormat(prise.end_at) }}</span>
            <datetime
              type="datetime"
              v-else-if="editMode"
              v-model="prise.end_at"
            ></datetime>
          </li>
          <li>
            <p>Quota de participants:</p>
            <input
              v-if="editMode"
              class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
              v-model.number="prise.quota"
            />
            <span v-else>{{ prise.quota }}</span>
          </li>
          <li>
            <p>Détails du RDV:</p>
            <textarea
              v-if="editMode"
              class="extended-textarea block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
              v-model="prise.private_details"
            />
            <span v-else>{{ prise.private_details }}</span>
          </li>
          <h3
            class="mt-4 mb-2 text-xl font-semibold underline text-gray-700 dark:text-gray-200"
          >
            Statistiques
          </h3>
          <li>
            <p>Participations:</p>
            <span>{{ prise.participants_count }}</span>
          </li>
          <li>
            <p>Participations en attente:</p>
            <span>{{ prise.participants_waiting_validation_count }}</span>
          </li>
          <li>
            <p>Participations refusées:</p>
            <span>{{ prise.participants_refused_count }}</span>
          </li>
        </ul>
      </div>
      <div>
        <input
          type="file"
          name="prise"
          accept="image/png, image/jpeg"
          @change="updatePrisePicture($event.target.files)"
        />
        <picture
          class="rounded-lg"
          id="prise-picture"
          :style="{
            backgroundImage: 'url(' + getPrisePicture(prise.image_url) + ')'
          }"
        ></picture>
      </div>
    </div>

    <h2
      v-if="prise.speakers && prise.speakers.length > 0"
      class="text-2xl font-semibold text-gray-700 dark:text-gray-200"
    >
      Speaker
    </h2>
    <div
      v-if="prise.speakers && prise.speakers.length > 0"
      class="w-full mb-8 overflow-hidden rounded-lg shadow-xs"
    >
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
            >
              <th class="px-4 py-3">Nom</th>
              <th class="px-4 py-3">Actions</th>
            </tr>
          </thead>
          <tbody
            class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
          >
            <tr
              v-for="(speaker, index) in prise.speakers"
              :key="index"
              class="text-gray-700 dark:text-gray-400"
            >
              <td class="px-4 py-3 text-sm">
                <div class="flex items-center text-sm">
                  <div
                    class="relative hidden w-8 h-8 mr-3 rounded-full md:block"
                    id="speaker-input"
                  >
                    <input
                      type="file"
                      name="speaker"
                      accept="image/png, image/jpeg"
                      @change="
                        updateSpeakerPicture(
                          speaker.id,
                          index,
                          $event.target.files
                        )
                      "
                    />
                    <img
                      class="object-cover w-full h-full rounded-full"
                      :src="getSpeakerImage(speaker.image)"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <input
                      class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                      v-model="speakerOnEdit.name"
                      v-if="speakerOnEdit && speakerOnEdit.id === speaker.id"
                    />
                    <p v-else class="font-semibold">
                      {{ speaker.name }}
                    </p>
                  </div>
                </div>
              </td>
              <td class="px-4 py-3">
                <div class="flex items-center space-x-4 text-sm">
                  <button
                    v-if="!speakerOnEdit || speakerOnEdit.index !== index"
                    class="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                    aria-label="Modifier"
                    @click="enterSpeakerEditMode(index)"
                  >
                    <svg
                      class="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                      ></path>
                    </svg>
                  </button>
                  <button
                    v-if="!speakerOnEdit || speakerOnEdit.index !== index"
                    class="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                    aria-label="Supprimer"
                    @click="openDeleteSpeakerModal(index)"
                  >
                    <svg
                      class="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                  <button
                    v-if="speakerOnEdit && speakerOnEdit.index === index"
                    class="px-3 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                    @click="saveSpeakerUpdate"
                  >
                    Sauvegarder
                  </button>
                  <button
                    v-if="speakerOnEdit && speakerOnEdit.index === index"
                    class="px-3 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                    @click="exitSpeakerEditMode"
                  >
                    Annuler
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Ajouter un speaker
    </h2>
    <form
      class="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800"
      :onsubmit="postNewSpeaker"
    >
      <label class="block text-sm">
        <span class="text-gray-700 dark:text-gray-400">Nom</span>
        <input
          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
          placeholder="Stéphanie Sit-Amet"
          name="name"
          v-model="newSpeaker.name"
        />
      </label>
      <button
        class="mt-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
        @click="postNewSpeaker"
      >
        Poster
      </button>
    </form>

    <h2
      v-if="
        prise.participants_waiting_validation &&
          prise.participants_waiting_validation.length > 0
      "
      class="text-2xl font-semibold text-gray-700 dark:text-gray-200"
    >
      Participations en attente
    </h2>
    <div
      v-if="
        prise.participants_waiting_validation &&
          prise.participants_waiting_validation.length > 0
      "
      class="w-full mb-8 overflow-hidden rounded-lg shadow-xs"
    >
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
            >
              <th class="px-4 py-3">Utilisateur</th>
              <th class="px-4 py-3">Actions</th>
            </tr>
          </thead>
          <tbody
            class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
          >
            <tr
              v-for="(user, index) in prise.participants_waiting_validation"
              :key="index"
              class="text-gray-700 dark:text-gray-400"
            >
              <router-link
                :to="{ name: 'Account', params: { id: user.id } }"
                class="px-4 py-3 td-router-link"
              >
                <div class="flex items-center text-sm">
                  <div
                    class="relative hidden w-8 h-8 mr-3 rounded-full md:block"
                  >
                    <img
                      class="object-cover w-full h-full rounded-full"
                      :src="getProfilePicture(user.profile_picture)"
                      alt=""
                      loading="lazy"
                    />
                    <div
                      class="absolute inset-0 rounded-full shadow-inner"
                      aria-hidden="true"
                    ></div>
                  </div>
                  <div>
                    <p class="font-semibold">
                      {{ user.first_name }} {{ user.last_name }}
                    </p>
                    <p class="text-xs text-gray-600 dark:text-gray-400">
                      {{ user.username }}
                    </p>
                  </div>
                </div>
              </router-link>
              <td>
                <button
                  class="px-3 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                  @click="
                    priseParticipationValidation(
                      user.participation_id,
                      true,
                      index
                    )
                  "
                >
                  Valider
                </button>
                <button
                  class="px-3 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                  @click="
                    priseParticipationValidation(
                      user.participation_id,
                      false,
                      index
                    )
                  "
                >
                  Refuser
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <h2
      v-if="prise.participants && prise.participants.length > 0"
      class="text-2xl font-semibold text-gray-700 dark:text-gray-200"
    >
      Participants
    </h2>
    <div
      v-if="prise.participants && prise.participants.length > 0"
      class="w-full mb-8 overflow-hidden rounded-lg shadow-xs"
    >
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
            >
              <th class="px-4 py-3">Utilisateur</th>
            </tr>
          </thead>
          <tbody
            class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
          >
            <tr
              v-for="(user, index) in prise.participants"
              :key="index"
              class="text-gray-700 dark:text-gray-400"
            >
              <router-link
                :to="{ name: 'Account', params: { id: user.id } }"
                class="px-4 py-3 td-router-link"
              >
                <div class="flex items-center text-sm">
                  <div
                    class="relative hidden w-8 h-8 mr-3 rounded-full md:block"
                  >
                    <img
                      class="object-cover w-full h-full rounded-full"
                      :src="getProfilePicture(user.profile_picture)"
                      alt=""
                      loading="lazy"
                    />
                    <div
                      class="absolute inset-0 rounded-full shadow-inner"
                      aria-hidden="true"
                    ></div>
                  </div>
                  <div>
                    <p class="font-semibold">
                      {{ user.first_name }} {{ user.last_name }}
                    </p>
                    <p class="text-xs text-gray-600 dark:text-gray-400">
                      {{ user.username }}
                    </p>
                  </div>
                </div>
              </router-link>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <h2
      v-if="prise.participants_refused && prise.participants_refused.length > 0"
      class="text-2xl font-semibold text-gray-700 dark:text-gray-200"
    >
      Participations refusées
    </h2>
    <div
      v-if="prise.participants_refused && prise.participants_refused.length > 0"
      class="w-full mb-8 overflow-hidden rounded-lg shadow-xs"
    >
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
            >
              <th class="px-4 py-3">Utilisateur</th>
            </tr>
          </thead>
          <tbody
            class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
          >
            <tr
              v-for="(user, index) in prise.participants_refused"
              :key="index"
              class="text-gray-700 dark:text-gray-400"
            >
              <router-link
                :to="{ name: 'Account', params: { id: user.id } }"
                class="px-4 py-3 td-router-link"
              >
                <div class="flex items-center text-sm">
                  <div
                    class="relative hidden w-8 h-8 mr-3 rounded-full md:block"
                  >
                    <img
                      class="object-cover w-full h-full rounded-full"
                      :src="getProfilePicture(user.profile_picture)"
                      alt=""
                      loading="lazy"
                    />
                    <div
                      class="absolute inset-0 rounded-full shadow-inner"
                      aria-hidden="true"
                    ></div>
                  </div>
                  <div>
                    <p class="font-semibold">
                      {{ user.first_name }} {{ user.last_name }}
                    </p>
                    <p class="text-xs text-gray-600 dark:text-gray-400">
                      {{ user.username }}
                    </p>
                  </div>
                </div>
              </router-link>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getOne,
  getPrisePicture,
  getSpeakerImage,
  priseParticipationValidation,
  update,
  updateImage,
  deleteSpeakerById,
  newSpeaker,
  updateSpeaker,
  updateSpeakerImage
} from "@/utils/api/prises";
import { getProfilePicture } from "@/utils/api/accounts";
import { format } from "@/utils/date";
import Modal from "@/components/Modal";

export default {
  components: {
    Modal
  },
  data() {
    return {
      prise: null,
      editMode: false,
      speakerOnEdit: {
        index: null,
        id: null,
        name: null
      },
      speakerDeleteModal: {
        index: null,
        open: false,
        title: "",
        description: null
      },
      newSpeaker: {
        name: null
      }
    };
  },
  mounted() {
    getOne(this.$route.params.id).then(res => {
      this.$data.prise = res.data;
    });
  },
  methods: {
    getProfilePicture: getProfilePicture,
    getPrisePicture: getPrisePicture,
    getSpeakerImage: getSpeakerImage,
    dateFormat: format,
    switchEditMode() {
      this.$data.editMode = !this.$data.editMode;
    },
    postNewSpeaker(e) {
      e.preventDefault();
      newSpeaker(this.$data.prise.id, this.$data.newSpeaker.name).then(res => {
        if (
          this.$data.prise.speakers == null ||
          this.$data.prise.speakers.length === 0
        ) {
          this.$data.prise.speakers = [res.data];
        } else {
          this.$data.prise.speakers.push(res.data);
        }
        this.$data.newSpeaker.name = null;
      });
    },
    enterSpeakerEditMode(index) {
      this.$data.speakerOnEdit = {
        index: index,
        id: this.$data.prise.speakers[index].id,
        name: this.$data.prise.speakers[index].name
      };
    },
    openDeleteSpeakerModal(index) {
      this.$data.speakerDeleteModal.index = index;
      this.$data.speakerDeleteModal.description = `Souhaitez-vous réellement supprimer le speaker "${this.$data.prise.speakers[index].name}" ?`;
      this.$data.speakerDeleteModal.open = true;
    },
    saveSpeakerUpdate() {
      updateSpeaker(
        this.$data.speakerOnEdit.id,
        this.$data.speakerOnEdit.name
      ).then(() => {
        this.$data.prise.speakers[
          this.$data.speakerOnEdit.index
        ].name = this.$data.speakerOnEdit.name;

        this.$data.speakerOnEdit = {
          id: null,
          name: null
        };
      });
    },
    exitSpeakerEditMode() {
      this.$data.speakerOnEdit = null;
    },
    speakerDelete() {
      deleteSpeakerById(
        this.$data.prise.speakers[this.speakerDeleteModal.index].id
      ).then(() => {
        this.$data.prise.speakers.splice(this.speakerDeleteModal.index, 1);
        this.$data.speakerDeleteModal.open = false;
      });
    },
    updateSpeakerPicture(id, index, file) {
      updateSpeakerImage(id, file[0]).then(res => {
        this.$data.prise.speakers[index].image = res.data.key;
      });
    },
    updatePrisePicture(files) {
      updateImage(this.$data.prise.id, files[0]).then(res => {
        this.$data.prise.image_url = res.data.key;
      });
    },
    saveUpdate() {
      update(
        this.$data.prise.id,
        this.$data.prise.title,
        this.$data.prise.intro,
        this.$data.prise.start_at,
        this.$data.prise.end_at,
        this.$data.prise.program,
        this.$data.prise.description,
        this.$data.prise.video_url,
        this.$data.prise.private_details,
        this.$data.prise.quota
      )
        .then(() => {
          this.switchEditMode();
        })
        .catch(err => {
          alert(err.response.data.data);
        });
    },
    priseParticipationValidation(participation_id, accepted, index) {
      // call api
      priseParticipationValidation(participation_id, accepted).then(() => {
        const user = this.$data.prise.participants_waiting_validation[index];

        // remove user from waiting list
        this.$data.prise.participants_waiting_validation.splice(index, 1);
        this.$data.prise.participants_waiting_validation_count -= 1;

        // if accepted, then add to participants list + null pointer verification
        if (accepted) {
          !this.$data.prise.participants
            ? (this.$data.prise.participants = [user])
            : this.$data.prise.participants.push(user);
          this.$data.prise.participants_count += 1;
        }

        // else, then add to refused participants list + null pointer verification
        else {
          !this.$data.prise.participants_refused
            ? (this.$data.prise.participants_refused = [user])
            : this.$data.prise.participants_refused.push(user);
          this.$data.prise.participants_refused_count += 1;
        }
      });
    }
  }
};
</script>

<style lang="sass" scoped>
ul, li
  width: 100%

.input-custom
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  opacity: 0.0
  transition-duration: 0.3s

  &:hover
    opacity: 0.5

  &::after
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: #000

#speaker-input > input
  @extend .input-custom
  border-radius: 50%

#top-presentation-block
  display: flex
  flex-flow: row wrap

  > :first-child
    flex: 1

  > div
    position: relative
    width: 30%
    padding: 35px

    > #prise-picture
      display: block
      height: 100%
      background-position: center
      background-size: cover

    > input
      @extend .input-custom
</style>
